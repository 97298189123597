import BigNumber from 'bignumber.js';
import usdcIcon from '../../images/tokens/usdc.svg';
import wethIcon from '../../images/tokens/weth.svg';
import wbtcIcon from '../../images/tokens/wbtc.svg';
import wmaticIcon from '../../images/tokens/wmatic.svg';
import aaveIcon from '../../images/tokens/aave.svg';

export function shortAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
}

export function getChainData(supportedChains, chainId) {
    const chainData = supportedChains.filter(
      (chain) => chain.chain_id === chainId
    )[0];
  
    if (!chainData) {
      throw new Error("ChainId missing or not supported");
    }
    return chainData;
}

export function getTokenIcon(symbol) {
    symbol = symbol.toLowerCase();
    switch(symbol) {
        case "usdc":
            return usdcIcon;
        case "weth":
            return wethIcon;
        case "wbtc":
            return wbtcIcon;
        case "wmatic":
            return wmaticIcon;
        case "aave":
            return aaveIcon;
        default:
            return "na"
    }
}

export function getContract(web3, abi, address) {
    return new web3.eth.Contract(abi, address);
}


//calculations

export function toPrecisin(value, dec) {
    if (value.toString().indexOf(".") > -1) {
      var valueA = value.toString().split(".");
  
      value = valueA[0] + "." + valueA[1].substring(0, dec);
    }
    return value;
}
  
export function isEmpty(value) {
    return value == null || value.length === 0;
}

export function decimal2Fixed(amount, decimals) {
    var tDec = new BigNumber("10")
        .exponentiatedBy(new BigNumber(decimals))
        .toFixed();
    return new BigNumber(amount).multipliedBy(tDec).toFixed();
}

export function fixed2Decimals(amount, decimals, precision=null, round = 0) {
    var tDec = new BigNumber("10")
        .exponentiatedBy(new BigNumber(decimals))
        .toFixed();
    let value = new BigNumber(amount).dividedBy(tDec);
    return precision ===null ? value.toFixed() : value.toFixed(precision, round)
}

export function mul(amount1, amount2) {
    return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed();
}

export function div(amount1, amount2) {
    return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed();
}

export function mulFixed(amount1, amount2) {
    return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed(0, 2);
}

export function divFixed(amount1, amount2) {
    return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed(0);
}