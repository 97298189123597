import { Navbar, Nav, Button, Container, ButtonGroup, ToggleButton} from "react-bootstrap"
import { connect } from "react-redux";
import { Link, useResolvedPath, useMatch } from  "react-router-dom";
import logo from '../../images/logo.png';
import { shortAddress } from '../utils';
import {toggleTheme} from '../redux/actions';

function NavBar(props) {
    const tabs = [
        {"title": "Dashboard", "to": "/"},
        {"title": "Portfolio", "to": "portfolio"}
        // {"title": "Markets", "to": "markets"}
    ]

    const getIconColor = value => {
        if(props.themeMode === "dark" && value === "dark") {
            return "text-primary"
        } else if ( props.themeMode === "light" && value === "light") {
            return "text-warning"
        } else {
            return ""
        }
     }

    return (
        <Navbar bg={props.themeMode === "dark" ? "black": "white"} className={props.themeMode === "dark" ? "" : "border-bottom"} expand="lg">
            <Container>
                <Navbar.Brand>
                    <img height="34" src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto me-auto">
                        {tabs.map((tab, i)=> {
                            return <NavItem tab={tab} key={i}/>
                        })}
                    </Nav>
                </Navbar.Collapse>
                <div className="d-flex align-items-center">
                    <ButtonGroup className="me-4">
                        {[
                            {"value": "dark", "icon": "fa fa-moon"},
                            {"value": "light", "icon": "fa fa-lightbulb"}
                        ].map((radio, idx) => (
                        <ToggleButton
                            title={`Switch to ${radio.value} theme`}
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={props.themeMode === radio.value ? props.themeMode : "primary"}
                            name="radio"
                            value={radio.value}
                            checked = {radio.value === props.themeMode}
                            onChange={(e) => props.toggleTheme(e.currentTarget.value)}
                        >
                            <i className={`${radio.icon} ${getIconColor(radio.value)}`}/>
                        </ToggleButton>
                        ))}
                    </ButtonGroup>
                    {props.connected  ? <div>
                        <span className="user-address"><i className="fa fa-user me-2"/>{shortAddress(props.address)}</span>
                    </div>: <Button onClick={()=> props.onConnect()} variant="primary gradient-y">Connect Wallet</Button>}
                   
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
        </Navbar>
    )
}

function NavItem(props) {
    let tab = props.tab;
    let resolved = useResolvedPath(tab.to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <Nav.Link active={match} as={Link} to={tab.to}>{tab.title}</Nav.Link>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        address: state.get("accAddress"),
        chainId: state.get("chainId"),
        networkId: state.get("networkId"),
        themeMode: state.get("themeMode")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleTheme: mode => dispatch(toggleTheme(mode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)