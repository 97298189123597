import { connect } from 'react-redux';
import { fixed2Decimals, getContract, toPrecisin } from '../utils';
import { Spinner} from 'react-bootstrap';
import { fetchBalanceAndAllowances, fetchNativeBalance } from '../redux/actions';
import { libABI } from '../utils/abi';
import BalanceHelp from './dashboard/components/modal/BalanceHelp';

function WalletBalances(props) {
    const token =  props.assets.find((t)=> t.get("symbol") === "USDC");
    const usdcAddress = token.get("uAddress");
    let usdcBalance = 0;
    if ( props.uTokenMeta.hasIn([usdcAddress, "balance"]) ) {
        usdcBalance =  Number(props.uTokenMeta.getIn([usdcAddress, "balance"]));
    }

    const refresh = () => {
        const contract = getContract(props.web3, libABI, props.libAddress);
        props.fetchBalanceAndAllowances(contract, props.accAddress, props.assets);
        props.fetchNativeBalance(props.web3, props.accAddress);
    }

    return (
        <div className='text-end mb-4'>
            <div className='wallet-balances'>
                <span className='me-4 fw-normal'><i className='fa  fa-wallet text-secondary me-1'/>Wallet Balance</span>
                <span>
                    {props.isLoadingNativeBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : toPrecisin(fixed2Decimals(props.nativeBalance, 18), 4)} MATIC</span>
                <span className='ms-4 me-4'>|</span>
                <span>{props.isLoadingUSDCBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : toPrecisin(fixed2Decimals(usdcBalance, 6), 2)} USDC </span>
                <span onClick={()=> refresh()} title="refresh" className='ms-4 px-1 py-0 rounded-3 btn btn-primary'><i className='mx-1 fa fa-refresh'/></span>
            </div>
            {(usdcBalance ===0 || props.nativeBalance === 0) && <div className="mt-2">
                <div style={{display: "inline-block"}}>
                    <BalanceHelp token={token} position="header"/>
                </div>
            </div>}
            {/* <div>
                <div className='text-center'>
                    <Row >
                        <Col className="bg-light">
                            <i className='fa fa-2x fa-wallet me-2 text-secondary'/>
                        </Col>
                        <Col>
                            <div className="text-light-2 fw-bold">{toPrecisin(fixed2Decimals(props.nativeBalance, 18), 4)} MATIC</div> 
                        </Col>
                        <Col>
                            | <span className='ms-2'>{toPrecisin(fixed2Decimals(usdcBalance, 6), 2)}USDC (Test Tokens) </span>
                        </Col>
                    </Row>
                </div>
            </div> */}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        libAddress: state.getIn(["contracts", "libABI"]),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        assets: state.get("assets"),
        isLoadingNativeBalance: state.get("isLoadingNativeBalance"),
        nativeBalance: state.get("nativeBalance"),
        isLoadingUSDCBalance: state.getIn(["balanceAndAllowances", "isLoading"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBalanceAndAllowances: (contract, accAddress, assets) => dispatch(fetchBalanceAndAllowances(contract, accAddress, assets)),
        fetchNativeBalance: (web3, address) => dispatch(fetchNativeBalance(web3, address))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalances);