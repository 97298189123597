import {useState} from 'react';
import {Modal, Row, Col, Button, Spinner} from 'react-bootstrap';
import Trophy from '../../../../images/trophy.png';

export default function MainnetModal(props) {
    const [address, setAddress] = useState("");
    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modal-header-image'>
                        <div className="image">
                            <img  src={Trophy} alt="rewards"/>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className='text-center'>
                    <img height="70" src={Trophy} alt="rewards"/>
                </div> */}
                <div className='p-4'>  
                    <div className='text-light-1'>
                        You are using Paxo Testnet on Mumbai Testnet (Polygon).<br/> <br/>
                        The most active Testnet users will be incentivised with Paxo tokens. Please update your Polygon Mainnet address below to receive rewards.
                    </div>
                    <div className='mt-3 small text-light-2'>
                        <i className='fa fa-info-circle me-1'/>You can update your Mainnet Address only once.
                    </div>
                    <input
                        className='form-control mt-4 mb-5'
                        value={address}
                        onChange={(e)=> setAddress(e.target.value)}
                        placeholder="Your Polygon Mainnnet Address 0x......."
                    />

                    <Row>
                        <Col>
                            <div className="d-grid">
                                {props.data.get("updateMessage") === null ? <Button
                                    onClick={()=> props.updateMainnetAccount(address.trim())}
                                    disabled={address.trim() === "" || props.data.get("isUpdating")}
                                    className="block" vairant="primary"
                                >
                                    {props.data.get("isUpdating") && <Spinner className="me-2" animation="grow" variant="secondary" size="sm"/>}
                                    Update
                                </Button> : 
                                <div><i className='fa fa-check-circle text-secondary me-2'></i>{props.data.get("updateMessage")}</div>
                                }
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid">
                                <Button variant="light" onClick={()=> props.onHide()}>Close</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    ) 
}
