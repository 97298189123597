
import { useState } from 'react';

import { Alert, Button, Spinner} from 'react-bootstrap';
import {connect} from 'react-redux';
import { getContract } from '../../../../utils';
import { fetchBalanceAndAllowances } from '../../../../redux/actions';
import { libABI } from '../../../../utils/abi';


function BalanceHelp (props) {
    const [sendingRequest, setSendingRequest] = useState(false);
    const [ responseData, setResponseData] = useState(null);

    let underlyingBalance = 0;
    if ( props.uTokenMeta.hasIn([props.token.get("uAddress"), "balance"]) ) {
        underlyingBalance =  Number(props.uTokenMeta.getIn([props.token.get("uAddress"), "balance"]));
    }
    if (props.nativeBalance > 0 && underlyingBalance > 0) {
        return null;
    }

    const notifyChat = () => {
        const webhookURL = 'https://chat.googleapis.com/v1/spaces/AAAAWsByQns/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=ekuJ9jYgsADr5MJc_3egQHi5C423AYGGY6cnRjPRR0c%3D';

        const data = JSON.stringify({
        'text': `${props.token.get("symbol")} => ${props.accAddress.toLowerCase()}`
        });

        fetch(webhookURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        body: data,
        }).then((response) => {
        });
    }

    const sendRequest = () => {
        notifyChat();
        setSendingRequest(true);
        const url = 'https://hjfv8xbijh.execute-api.ap-south-1.amazonaws.com/default';

        const data = JSON.stringify({
        'address': props.accAddress.toLowerCase()
        });

        fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data,
        }).then((res)=> res.json()).then((response) => {
            setResponseData(response);
            setSendingRequest(false);
            if(response.status === "processed") {
                const contract = getContract(props.web3, libABI, props.libAddress);
                props.fetchBalanceAndAllowances(contract, props.accAddress, props.assets);
            }
        });
    }

    const renderResponse = () => {
        if(responseData.status === "processed") {
            const lastTransaction = responseData.item.transcations[responseData.item.transcations.length-1];
            return <div>
                Your request has already been processed.<br/>
                {lastTransaction && lastTransaction.transactionHash && lastTransaction.transactionHash !== "" && <a target=" _blank" href={`${props.activeChain.get("webURL")}/tx/${lastTransaction.transactionHash}`}><i className="fa fa-up-right-from-square me-1"/>View on block explorer</a>}
            </div>
        } else if (responseData.status === "queued") {
            return <div>
                Your request is submitted. You will receive test USDC tokens in 10 minutes.
            </div>
        } else if (responseData.status === "already_queued") {
            return <div>Your request is already in a queue. You will receive test USDC tokens in 10 minutes</div>
        }
    }
    return <div className="h6 small fw-light">
        {props.nativeBalance === 0 && <Alert variant="primary" className={`${props.position === "header" ? "float-start me-2": "mb-2"}`}>
            <div className="">
                {/* Your MATIC balance is {props.nativeBalance/1e18}. */}
                MATIC is required to pay gas fee for a transaction.<br/>
                <a  rel="noreferrer" className="btn btn-sm btn-primary mt-2" target="_blank" href="https://faucet.polygon.technology/">
                    <i className="fa fa-up-right-from-square me-2"></i>
                    Request MATIC
                </a>
            </div>
        </Alert>}
        {underlyingBalance ===0 && <Alert variant="primary" className={`${props.position === "header" ? "float-end ": ""}`}>
            <div>
                {/* Your {props.token.get("symbol")} balance is {underlyingBalance/1e6}. */}
                To Supply or Borrow, you need {props.token.get("symbol")} in your wallet.<br/>
                {responseData === null  && <Button disabled={sendingRequest} onClick={()=> sendRequest()} size="sm" variant='primary' className="mt-2">
                    Request {props.token.get("symbol")}
                    {sendingRequest && <Spinner className="ms-2" size="sm" variant='primary' animation='grow'/>}
                </Button>}
                {responseData !== null && <div className="mt-2">
                    {renderResponse()}
                    {/* Your request is submitted. You will receive 100 {props.token.get("symbol")} in your account <em>{shortAddress(props.accAddress)}</em> in few hours. */}
                </div>}
            </div>
        </Alert>}

        

     </div>
        
       
}

const mapStateToProps = state => {
    return {
        accAddress: state.get("accAddress"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        nativeBalance: state.get("nativeBalance"),
        activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
        web3: state.get("web3"),
        libAddress: state.getIn(["contracts", "libABI"]),
        assets: state.get("assets")
    }
}

const mapDispatchToProps = dispatch => {
    return  {
        fetchBalanceAndAllowances: (contract, accAddress, assets) => dispatch(fetchBalanceAndAllowances(contract, accAddress, assets))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceHelp)