
export const transaction = {
    isActive: false,
    isLoading: false,
    isCompleted: false
};

export const mainnetAccount =  {
    isFetched: false,
    status: 0,
    message: "",
    isUpdating: false,
    updateMessage: null
}

const mumbaiTestnetAssets = [
    {
        "name": "USD Coin",
        "symbol": "USDC",
        "address": "0x57c5c55A295b0040e22a8E3FBFCE9d1d81860BB3",
        "uAddress": "0xD33572f6DD1bb0D99C8397c8efE640Cf973EaF3B",
        "decimals": 6,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false
    },
    {
        "name": "WMATIC",
        "symbol": "WMATIC",
        "address": "0xE952e7E780b1759b7CF35C36c63B2d3620Fd9369",
        "uAddress": "0x64659Fa9064Bb434daA6E8e0b2706D01e9f9e30F",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true
    },
    {
        "name": "AAVE",
        "symbol": "AAVE",
        "address": "0x1ECf0E8fDe5b68cA746dBE1E1989ac68B67571bc",
        "uAddress": "0x7048f7a5D4781BB097Cb839171832e96e8aFa27f",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true
    },
    {
        "name": "WETH",
        "symbol": "WETH",
        "address": "0x19ac5b578615450C8D3e50Adcc37Ae2C63822c90",
        "uAddress": "0xe37f602e3dF0f7B2b0374EDd55F54272543442F1",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true
    }

];

const defaultState =  {
    themeMode: "dark",
    network: "mumbai-testnet",
    connected: false,
    networkId: null,
    chainId: null,
    accAddress: "",
    web3: null,
    isLoadingNativeBalance: false,
    nativeBalance: -1,
    contracts: {
        libABI: "0x5149fde5D6cac2615186149a5Db0F1DA754c7542",
        uniswapQuoter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        comptroller: "0xBE62faa90fcfC4F8Eaf1Ad52fA37b62D98bC807d"
    },
    supportedChains: [
        // {
        //     name: "Ethereum Ropsten",
        //     shortName: "rop",
        //     chain: "ETH",
        //     network: "ropsten",
        //     chainId: 3,
        //     networkId: 3,
        //     nativeCurrency: {
        //         symbol: "ETH",
        //         name: "Ethereum",
        //         decimals: "18",
        //         contractAddress: "",
        //         balance: ""
        //     },
        //     webURL: "https://ropsten.etherscan.io"
        // },
        {
            name: "Mumbai-Testnet (Polygon)",
            shortName: "",
            chain: "ETH",
            network: "",
            chainId: 80001,
            networkId: 80001,
            nativeCurrency: {
                symbol: "MATIC",
                name: "MATIC",
                decimals: "18",
                contractAddress: "",
                balance: ""
            },
            webURL: "https://mumbai.polygonscan.com"
        }
    ],
    assets: mumbaiTestnetAssets,
    accountSnapshot: {
        isLoading: false
    },
    balanceAndAllowances: {
        isLoading: false,
        uTokens: {}
    },
    swapEstimates: {
    },
    totalUnderlyingCash: {

    },
    transaction,
    mainnetAccount
}


// const ropstenAssets =  [
//     {
//         "name": "USD Coin",
//         "symbol": "USDC",
//         "address": "0x82420C8f17D88a6BBa8dFd49a89D4808ca06Cb48",
//         "uAddress": "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
//         "decimals": 6,
//         "lendEnabled": true,
//         "borrowEnabled": true,
//         "buyEnabled": false
//     },
//     {
//         "name": "WETH",
//         "symbol": "WETH",
//         "address": "0x6A8B2cDd084208d25Bc70f457FFE35E01338106b",
//         "uAddress": "0xc778417E063141139Fce010982780140Aa0cD5Ab",
//         "decimals": 18,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     },
//     {
//         "name": "WBTC",
//         "symbol": "WBTC",
//         "address": "0x4F8818fAc203E3481F86123483c139A025f648eE",
//         "uAddress": "0x442be68395613bdcd19778e761f03261ec46c06d",
//         "decimals": 8,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     }
// ]



export default defaultState;