import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button, Dropdown, DropdownButton} from 'react-bootstrap';
import {getTokenIcon, toPrecisin} from '../../../utils';
import {fromJS} from 'immutable';
import LendModal from './LendModal';
import BorrowModal from './BorrowModal';

function BorrowAssets(props) {
    const [activeToken, setActiveToken] = useState(null);

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getBuyFactor = (address) => {
        let b = props.accountSnapshot.getIn([address, "buyFactor"]);
        return b ? `${b}x` : "---" ;
    }


    return (
        <Card body className="mt-5">
            <Row>
                <Col className="g-0">
                    <div className="fs-6">Borrow &amp; Invest</div>
                    <div className="small text-muted">Tokens you can borrow at a lower collateral</div>
                </Col>
            </Row>
            <div className="token-list">
                {props.assets.filter((item)=> item.get("buyEnabled")).map((token, j)=> <BorrowItem 
                    key={j}
                    token={token}
                    borrowAssets={props.assets.filter((item)=> item.get("borrowEnabled") && item.get("address") !== token.get("address"))}
                    getAPY={(address)=> getAPY(address)}
                    getBuyFactor={(address)=> getBuyFactor(address)}
                    onBuy={(borrowToken)=> setActiveToken(fromJS({targetToken: token.toJS(), borrowToken: borrowToken.toJS()}))}
                />)}
            </div>
            {activeToken !==null && <BorrowModal
                show={activeToken !== null}
                tokens={activeToken}
                getAPY={(address) => getAPY(address)}
                onHide={()=> setActiveToken(null)}
            />}
        </Card>
    )
}


function BorrowItem(props) {
    const [borrowOptions, setBorrowOptions] = useState([]);
    const [activeBorrowAsset, setActiveBorrowAsset] = useState(fromJS({}));
    useEffect( ()=> {
        setBorrowOptions(props.borrowAssets);
        setActiveBorrowAsset(props.borrowAssets.get(0));
    }, []);

    const token = props.token;

    return (
        <Row  className="token-list-item align-items-center">
            <Col>
                <div className="title">Asset</div>
                <div className="value"><img src={getTokenIcon(token.get("symbol"))} alt="token"/>{token.get("symbol")}</div>
            </Col>
            <Col>
                <div className="title">Pay &amp; Borrow</div>
                {activeBorrowAsset.has("symbol") && <div className="value">
                    <img src={getTokenIcon(activeBorrowAsset.get("symbol"))} alt="token"/>{activeBorrowAsset.get("symbol")}
                {/* <DropdownButton
                    variant="outline-secondary"
                    title={activeBorrowAsset.has("symbol") ? <span><img src={getTokenIcon(activeBorrowAsset.get("symbol"))} alt="token"/> {activeBorrowAsset.get("symbol")}</span> : ""}
                >
                    {borrowOptions.map((token, i)=> {
                        return (
                            <Dropdown.Item onClick={()=> setActiveBorrowAsset(token)} key={i} active={activeBorrowAsset === token.get("address")}>
                                <img src= {getTokenIcon(token.get("symbol"))}/>{token.get("symbol")}
                            </Dropdown.Item>
                        )
                    })}
                </DropdownButton> */}
                </div>}
            </Col>
            <Col>
                <div className="title">Interest</div>
                <div className="value">{props.getAPY(activeBorrowAsset.get("address"))}</div>
            </Col>
            <Col>
                <div className="title">Borrow Upto</div>
                <div className="value">{props.getBuyFactor(activeBorrowAsset.get("address"))}</div>
            </Col>
            <Col className='d-grid justify-content-end'>
                <Button onClick={()=> props.onBuy(activeBorrowAsset)} variant="primary" className="action">Borrow</Button>
            </Col>
        </Row>

    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot")
    }
}

export default connect(mapStateToProps, {})(BorrowAssets);