import { decimal2Fixed } from "../utils";
const addressMapURL = "https://hjfv8xbijh.execute-api.ap-south-1.amazonaws.com/default/addressmap";

export const updateAccount = (data) => {
	return {
		type: "UPDT_ACCOUNT",
		data
	}
}

const requestAccountSnapshot = data => {
	return {
		type: "REQ_ACC_SNAPSHOT"
	}
}

const receiveAccountSnapshot = (error, data) => {
	return {
		type: "REC_ACC_SNAPSHOT",
		error, data
	}
}


export const fetchAccountSnapshot = (contract, accAddress, tokens) => {
	return dispatch => {
		dispatch(requestAccountSnapshot());
		// console.log("Account Snapshot", accAddress, tokens);
		contract.methods.multiGetAccountSnapshot(tokens, accAddress)
        .call((error, result) => {
			// console.log("Snapshot Response", result);
			if(error) {
				// console.log("Snapshot error", error);
			}
			dispatch(receiveAccountSnapshot(error, result));
		});
	}	
}

const requestBalanceAndAllowances = data => {
	return {
		type: "REQ_ALLOW_BAL"
	}
}

const receiveBalanceAndAllowances = (error, data) => {
	return {
		type: "REC_ALLOW_BAL",
		error, data
	}
}


const requestNativeBalance = () => {
	return {
		type: "REQ_NATIVE_BAL"
	}
}

export const setNativeBalance = (balance) => {
	return {
		type: "REC_NATIVE_BAL",
		balance
	}
}

export const fetchNativeBalance = (web3, accAddress) => {
	return dispatch => {
		dispatch(requestNativeBalance());
		web3.eth.getBalance(accAddress)
		.then((res)=> {
			dispatch(setNativeBalance(Number(res)));
		});
	}	
}

export const fetchBalanceAndAllowances = (contract, accAddress, assets) => {
	return dispatch => {
		const tokens = assets.map((item) => item.get("uAddress")).toJS();
		const pTokens = assets.map((item) => item.get("address")).toJS();
		// console.log("TOKENS", [tokens, pTokens]);
		dispatch(requestBalanceAndAllowances());
		contract.methods.multiAllowanceAndBalance(tokens, accAddress, pTokens)
		.call((error, result) => {
			// console.log("balances result", result);
			dispatch(receiveBalanceAndAllowances(error, result));
		});
	}	
}

const requestFetchEstimate = (data) => {
	return {
		type: "REQ_ESTIMATE",
		data
	}
}
const receiveEstimate = (data, error, result) => {
	return {
		type: "REC_ESTIMATE",
		data,
		error,
		result
	}
}

export const fetchEstimate = (contract, data) => {
	// console.log("Estimate Payload", data);
	return dispatch => {
		dispatch(requestFetchEstimate(data));
		// contract.methods.estimateAmountIn(data.targetToken, data.decimalValue)
		contract.methods.getEstimateAmountsOut(
            data.uniswapQuoter,
            data.borrowToken,
            data.decimalValue,
            data.targetToken,
            decimal2Fixed(3, 3)
        )
		.call((error, result) => {
			// console.log("estimate result", result, error);
			dispatch(receiveEstimate(data, error, result));
		});
	}	
}

const receiveTotalCash = (address, result) => {
	return {
		type: "REC_TOTAL_CASH",
		address,
		result
	}
}

export const fetchTotalCash = (contract, address) => {
	return dispatch => {
		contract.methods.getCash()
		.call((error, result) => {
			// console.log("total cash result", result, error);
			dispatch(receiveTotalCash(address, result));
		});
	}
}

const requestTransaction = (txDesc) => {
	return {
		type: "REQ_TRANS",
		txDesc
	}
}

const updateTransactionId = (txId) => {
	return {	
		type: "UPD_TRANS_ID",
		txId
	}
}

const receiveTransaction = (success, response) => {
	return {
		"type": "REC_TRANS",
		success,
		response
	}
}

export const resetTransaction = () => {
	return {
		"type": "RESET_TRANS"
	}
}

const checkTransaction = (web3, txId) => {
	return dispatch => {
		// console.log("Checking Transaction", txId);
		web3.eth.getTransactionReceipt(txId, (err, response) => {
			// console.log("Check TXN ", response);
			if (response) {
				// console.log("Calling recieved trans");
				dispatch(receiveTransaction(true, response));
			} else {
				setTimeout(()=> {
					dispatch(checkTransaction(web3, txId));
				}, 3000)
			}
		})
	}
}

export const supplyTokens = (web3, contract, accAddress, amount, data) => {
	return dispatch => {
		dispatch(requestTransaction(data.txDesc));
		// setTimeout(()=> {
		// 	dispatch(updateTransactionId("0x97bc45e23bf934bc7aa165ad2dcd9a4ec4bdd3f610ae2e0501eb55e4cec66bbe"));
		// }, 3000);

		// setTimeout(()=> {
		// 	// dispatch(receiveTransaction(true, {}));
		// 	dispatch(checkTransaction(web3, "0x97bc45e23bf934bc7aa165ad2dcd9a4ec4bdd3f610ae2e0501eb55e4cec66bbe"))
		// }, 6000)

		// setTimeout(()=> {
		// 	dispatch(receiveTransaction(false, {}));
		// }, 9000)
		contract.methods.mint(amount)
		.send({from: accAddress})
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const borrowAndBuy = (web3, contract, accAddress, data) => {
	return dispatch => {
		dispatch(requestTransaction("borrow"));
		contract.methods.borrowAndBuy(data.targetAddress, data.borrowAmount, data.partialAmount, data.targetAmount)
		.send({ from: accAddress })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const repayBorrow = (web3, contract, accAddress, amount) => {
	return dispatch => {
		dispatch(requestTransaction("repay"));
		contract.methods.repayBorrow(amount)
		.send({ from: accAddress })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const redeemUnderlying = (web3, contract, accAddress, amount) => {
	return dispatch => {
		dispatch(requestTransaction("redeem"));
		contract.methods.redeemUnderlying(amount)
		.send({ from: accAddress })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction sError", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const approveToken = (web3, contract, accAddress, tokenAddress) => {
	return dispatch => {
		let  maxAllow = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
		dispatch(requestTransaction("approve"));
		contract.methods.approve(tokenAddress, maxAllow)
		.send({ from: accAddress })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction sError", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}


export const toggleTheme = mode => {
	return {
		"type": "TOGGLE_THEME",
		mode
	}
}

const resetMainnetAccount  = () => {
	return {
		"type": "RESET_MAINNET_ACC"
	}
}

const receiveMainnetAccount = data => {
	return {
		"type": "REC_MAINNET_ACC",
		data
	}
} 


export const fetchMainnetAccount = address => {
	return dispatch => {
		dispatch(resetMainnetAccount());
		fetch(addressMapURL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({fetch: true, address})
		}).then((res)=> res.json()).then((response) => {
			// console.log("Fetch Response", response);
			dispatch(receiveMainnetAccount(response));
		});
		// setTimeout(()=> {
		// 	dispatch(receiveMainnetAccount({"message": "Not mapped", "status": 0}))
		// 	// dispatch(receiveMainnetAccount({"message": "Saved successfully!", "status": 1}))
		// 	// dispatch(receiveMainnetAccount({"message": "Your mainnet address is already linked to this accounnt", "status": 2}))
		// }, 3000)
	}
}

const toggleMainnetAddressUpdate= (status, message)=> {
	return {
		"type": "TOGGLLE_MAINNET_ADDR_UPD",
		status,
		message
	}
}


export const updateMainnetAccount = data => {
	// console.log("Data", data);
	return dispatch => {
		dispatch(toggleMainnetAddressUpdate(true, null));
		fetch(addressMapURL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({update: true, address: data.address, mainnetAddress: data.mainnetAddress })
		}).then((res)=> res.json()).then((response) => {
			// console.log("Update Response", response);
			dispatch(toggleMainnetAddressUpdate(false, response.message));
		});
		// setTimeout(()=> {
		// 	dispatch(toggleMainnetAddressUpdate(false, "Updated successfully!"));
		// 	// dispatch(receiveMainnetAccount({"message": "Saved successfully!", "status": 1}))
		// 	// dispatch(receiveMainnetAccount({"message": "Your mainnet address is already linked to this accounnt", "status": 2}))
		// }, 5000)
	}
}

