import {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import { decimal2Fixed, fixed2Decimals, getContract, getTokenIcon } from '../../../../utils';
import { supplyTokens } from '../../../../redux/actions';
import { pTokenABI } from '../../../../utils/abi';
import BalanceHelp from './BalanceHelp';

function Lend(props) {
    const [sliderBalance, setSliderBalance] = useState(0);
    const token = props.token;
    let walletBalance = 0;
    if ( props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
        let balance = Number(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]));
        walletBalance = fixed2Decimals(balance, token.get("decimals"));
    }

    const supply = () => {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        const supplyAmount =   decimal2Fixed(sliderBalance, token.get("decimals"));
        const data  =  {
            txDesc: `Supply ${token.get("symbol")} ${sliderBalance}`
        }
        props.supplyTokens(props.web3, contract, props.accAddress, supplyAmount, data);
        props.onHide();
    }
    
    return(
        <div>
            <BalanceHelp token={token}/>
            <small className='text-light-1'>Supply Rates</small>
            <Row className='mt-1'>
                <Col>
                    <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                    <span className='text-light-2 fw-bold'>Supply APY</span>
                </Col>
                <Col className="text-end text-dark fw-bold">
                    {props.lendAPY}
                </Col>
            </Row>
            <Row className="mt-4 mb-2 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Supply {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(e.target.value)}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(walletBalance)}className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div>
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>

            {Number(sliderBalance) > walletBalance && <Alert variant="secondary" className="mt-4 small fw-bold">
                <small>
                    Insufficient wallet balance
                    <span className="ms-3 me-3 text-muted">|</span>
                    <i className="fa fa-wallet"/> {walletBalance} {token.get("symbol")}
                </small>
            </Alert>}

            <div className='d-grid mt-5'>
                <Button
                    disabled={Number(sliderBalance) <=0 || Number(sliderBalance) > walletBalance} size="lg"
                    onClick={()=> supply()}
                >LEND</Button>
            </div>

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        supplyTokens: (web3, contract, accAddress, amount, data)=> dispatch(supplyTokens(web3, contract, accAddress, amount, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lend);