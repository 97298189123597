import {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Spinner} from 'react-bootstrap';
import { decimal2Fixed, div, fixed2Decimals, getContract, getTokenIcon, mul, divFixed } from '../../../../utils';
import { redeemUnderlying } from '../../../../redux/actions';
import { comptrollerABI, pTokenABI } from '../../../../utils/abi';

function Redeem(props) {
    const [sliderBalance, setSliderBalance] = useState(0);
    const [shortfall, setShortfall] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const token = props.token;
    let walletBalance = 0;
    if ( props.snapshot.hasIn([token.get("address"), "lendBalance"]) ) {
        let balance = Number(props.snapshot.getIn([token.get("address"), "lendBalance"]));
        walletBalance = fixed2Decimals(balance, token.get("decimals"));
    }

    const redeem = () => {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        props.redeemUnderlying(props.web3, contract, props.accAddress, decimal2Fixed(sliderBalance, token.get("decimals")));
        props.onHide();
    }

    const checkShortfall = async () => {
        setIsLoading(true);
        const contract = getContract(props.web3, comptrollerABI, props.comptroller);
        const balance = decimal2Fixed(sliderBalance, token.get("decimals"));
        const tokens = divFixed(balance, fixed2Decimals(props.accountSnapshot.getIn([token.get("address"), "exchangeRateStored"]), 18));

        await contract.methods.getHypotheticalAccountLiquidity(
            props.accAddress,
            token.get("address"),
            tokens,
            0
        ).call((error, result)=> {
            const shtfall = Number(result["2"]);
            if(shtfall === 0) {
                redeem();
            } else {
                setShortfall(shtfall);
                setIsLoading(false);
            }
        });
    }

    return(
        <div>
            {/* <div className="mt-4 mb-2"><small className='text-light-1'>Withdraw {token.get("symbol")}</small></div> */}
            {shortfall === null && <Row className="mb-4 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Withdraw {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(Number(e.target.value))}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(walletBalance)} className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    {/* <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div> */}
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>}
           
            {shortfall === null  &&  <div className='d-grid mt-4'> 
                <Button
                    size="lg"
                    disabled={Number(sliderBalance) <=0 || Number(sliderBalance) > walletBalance || isLoading}
                    onClick={()=> checkShortfall()}
                >Withdraw {isLoading && <Spinner className="ms-2"  size="sm" variant="light" animation='grow'/>}</Button>
            </div>}
            {shortfall !== null && shortfall > 0 && <div>
                <div className="mb-4 h5 fw-bold text-light-2 text-center">Withdraw {sliderBalance} {token.get("symbol")}</div>
                <div className="mb-5 text-center">
                    Please check and repay your borrow balance. Your account may shortfall and withdraw will not happen.
                    <br/><br/>Do you still want to continue?</div>
                <div className="row mb-4">
                    <Col className="d-grid">
                        <Button variant='secondary' size="lg" onClick={()=> setShortfall(null)}>Cancel</Button>
                    </Col>
                    <Col className="d-grid">
                        <Button size='lg' onClick={()=> redeem()}>Proceed</Button>
                   </Col>
                </div>
            </div>}

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        comptroller: state.getIn(["contracts", "comptroller"]),
        accountSnapshot: state.get("accountSnapshot")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redeemUnderlying: (web3, contract, accAddress, amount) => dispatch(redeemUnderlying(web3, contract, accAddress, amount))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redeem);