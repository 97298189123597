import defaultState, { transaction, mainnetAccount } from './defaultState';
import { fromJS} from 'immutable';
import {decimal2Fixed, fixed2Decimals} from '../utils';

export default function reducer(state = fromJS(defaultState), action) {
    switch (action.type) {
        case "TOGGLE_THEME":
            return state = state.set("themeMode", action.mode);
        case "UPDT_ACCOUNT":
            return updateAccountData(state, action.data);
        case "REQ_NATIVE_BAL":
            return state.set("isLoadingNativeBalance", true);
        case "REC_NATIVE_BAL":
            state = state.set("isLoadingNativeBalance", false);
            return state.set("nativeBalance", action.balance);
        case "REQ_ACC_SNAPSHOT":
            return state.setIn(["accountSnapshot", "isLoading"], true);
        case "REC_ACC_SNAPSHOT":
            state = processAccountSnapshot(state, action.data);
            return state.setIn(["accountSnapshot", "isLoading"], false);
        case "REQ_ALLOW_BAL":
            return state.setIn(["balanceAndAllowances", "isLoading"], true);
        case "REC_ALLOW_BAL":
            state = processBalanceAndAllowances(state, action.data);
            return state.setIn(["balanceAndAllowances", "isLoading"], false);
        case "REQ_ESTIMATE":
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], true);
        case "REC_ESTIMATE":
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], false);
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "error"], action.error);
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "estimate"], action.result);
        case "REC_TOTAL_CASH":
            return state.setIn(["totalUnderlyingCash", action.address],  action.result);
        case "REQ_TRANS":
            state = state.set("transaction", fromJS({}));
            state = state.setIn(["transaction", "txDesc"], action.txDesc);
            state = state.setIn(["transaction", "isCompleted"], false);
            state = state.setIn(["transaction", "isActive"], true);
            return state.setIn(["transaction", "isLoading"], true);
        case "UPD_TRANS_ID":
            return state.setIn(["transaction", "txId"], fromJS(action.txId));
        case "REC_TRANS":
            state = state.setIn(["transaction", "response"], fromJS(action.response));
            state = state.setIn(["transaction", "isSuccess"], action.success);
            state = state.setIn(["transaction", "isCompleted"], true);
            return state.setIn(["transaction", "isLoading"], false);
        case "RESET_TRANS":
            return state.set("transaction", fromJS(transaction));
        case "RESET_MAINNET_ACC":
            return state.set("mainnetAccount", fromJS(mainnetAccount));
        case "REC_MAINNET_ACC":
            state = state.setIn(["mainnetAccount", "isFetched"], true);
            state = state.setIn(["mainnetAccount", "status"], action.data.status);
            return state.setIn(["mainnetAccount", "message"], action.data.message);
        case "TOGGLLE_MAINNET_ADDR_UPD":
            state = state.setIn(["mainnetAccount", "isUpdating"], action.status);
            return state.setIn(["mainnetAccount", "updateMessage"], action.message);
        default:
            return state;
    }
}

function updateAccountData(state, data) {
    let keys =  Object.keys(data);
    for(let i=0; i < keys.length; i++) {
        state = state.set(keys[i], data[keys[i]]);
    }
    return state;
}

function processAccountSnapshot(state, data) {
    const tokens = state.get("assets").map((item)=> item.get("address"));
    // const lendBalances = data[0];
    // const borrowBalances = data[1];
    // const supplyRPBlock = data[2];
    // const borrowRPBlock = data[3];
    // const buyPercAll = data[4];

    // balanceOfUnderlying: "10567367611"
    // borrowBalanceCurrent: "3495235"
    // borrowRatePerBlock: "12385057779"
    // buyFactor: "2000000000000000000"
    // supplyRatePerBlockNerr: "1612432066"
    // totalBorrowsCurrent: "1416155911"
    // totalCash: "9461308729"
    //prepare lend balances
    let snapshot = fromJS({});
    for ( let i=0; i< tokens.size; i++) {
        // snapshot = snapshot.setIn([tokens.get(i), "lendBalance"], lendBalances[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "borrowBalance"], borrowBalances[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "supplyRPBlock"], supplyRPBlock[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "borrowRPBlock"], borrowRPBlock[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "buyFactor"], fixed2Decimals(buyPercAll[i], 18));
        // var tmpLendAPY = (Math.pow(
        //     (supplyRPBlock[i] / decimal2Fixed(1, 18)) * 5760 + 1,
        //     365
        //     ) -
        //     1) *
        //     100;
        // var tmpBorrowAPY = (Math.pow(
        //     (borrowRPBlock[i] / decimal2Fixed(1, 18)) * 5760 + 1,
        //     365
        //     ) -
        //     1) *
        //     100;

        const row = data[i];
        snapshot = snapshot.setIn([tokens.get(i), "lendBalance"], Number(row.balanceOfUnderlying));
        snapshot = snapshot.setIn([tokens.get(i), "borrowBalance"], Number(row.borrowBalanceCurrent));
        snapshot = snapshot.setIn([tokens.get(i), "supplyRPBlock"], Number(row.supplyRatePerBlockNerr));
        snapshot = snapshot.setIn([tokens.get(i), "borrowRPBlock"], Number(row.borrowRatePerBlock));
        snapshot = snapshot.setIn([tokens.get(i), "buyFactor"], fixed2Decimals(row.buyFactor, 18));
        snapshot = snapshot.setIn([tokens.get(i), "totalCash"], Number(row.totalCash));
        snapshot = snapshot.setIn([tokens.get(i), "totalBorrowsCurrent"], Number(row.totalBorrowsCurrent));
        snapshot = snapshot.setIn([tokens.get(i), "totalSupply"], Number(row.totalSupply));
        snapshot = snapshot.setIn([tokens.get(i), "exchangeRateStored"], Number(row.exchangeRateStored));

        var tmpLendAPY = (Math.pow(
            (Number(row.supplyRatePerBlockNerr) / decimal2Fixed(1, 18)) * 5760 + 1,
            365
            ) -
            1) *
            100;
        var tmpBorrowAPY = (Math.pow(
            (Number(row.borrowRatePerBlock) / decimal2Fixed(1, 18)) * 5760 + 1,
            365
            ) -
            1) *
            100;
        snapshot = snapshot.setIn([tokens.get(i), "lendAPY"], tmpLendAPY);
        snapshot = snapshot.setIn([tokens.get(i), "borrowAPY"], tmpBorrowAPY);
    }
    state = state.set("accountSnapshot", snapshot);
    return state;
}

function processBalanceAndAllowances(state, data) {
    const tokens = state.get("assets").map((item) => item.get("uAddress"));
    const allowances = data[0];
    const balances = data[1];
    // const data = state.getIn(["balanceAndAllowances", "uTokens"])
    let tokenData = fromJS({});
    for ( let i=0; i<tokens.size; i++) {
        tokenData = tokenData.setIn([tokens.get(i), "allowance"] , allowances[i]);
        tokenData = tokenData.setIn([tokens.get(i), "balance"] , balances[i]);
    }
    state = state.setIn(["balanceAndAllowances", "uTokens"], tokenData);
    return state;
}
