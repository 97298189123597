import { useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { resetTransaction } from '../../../redux/actions';

function TransactionModal(props) {
    const usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const tx = props.transaction;

    const prevIsCompleted = usePrevious(tx.get("isCompleted"));
    const isCompleted = tx.get("isCompleted");
	useEffect(()=> {
		if(prevIsCompleted ===false && isCompleted === true) {
            // console.log("Refreshing Balances");
			props.refreshBalances();
		}
	}, [isCompleted]);


    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
             <Modal.Header className="justify-content-center">
                {tx.get("isLoading") && <div className="text-center"> 
                    {/* <i className="fa fa-3x fa-spin fa-spinner text-secondary me-2"/> */}
                    <Spinner variant='secondary' size="lg" animation='grow'/>
                    <div className="mt-3 fw-bold">Your Transcation is being processed.</div>
                </div>}
                {tx.get("isCompleted") === true && <div className="text-center">
                    <i className={`fa fa-2x fa-circle-${tx.get("isSuccess") === true ? "check text-success" : "xmark text-danger"}`}/>
                    {tx.get("isSuccess") === false && <div className="fw-bold text-light-2 mt-2"><small>Transaction Failed</small></div>}
                </div>}
            </Modal.Header>
            <Modal.Body className="px-4">
                {/* {tx.get("txDesc")} */}
                {tx.has("txId") && tx.get("txId") !== null && <div className="">
                    <div className="text-light-2">Transaction Id</div>
                    <div className="transaction-hash fw-bold text-break">
                        <small>{tx.get("txId")}</small>
                    </div>
                    <a className="text-light-2" target=" _blank" href={`${props.activeChain.get("webURL")}/tx/${tx.get("txId")}`}><small> <i className="fa fa-up-right-from-square me-1"/>View on block explorer</small> </a>
                </div>}
            </Modal.Body>
            {tx.get("isCompleted") === true && <Modal.Footer>
                <Button variant='primary' onClick={()=> props.onHide()}>Close</Button>
            </Modal.Footer>}
        </Modal>
    ) 
}

const mapStateToProps = state => {
   return {
       transaction: state.get("transaction"),
       chainId: state.get("chainId"),
       activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
       themeMode: state.get("themeMode")
   }
}

const mapDispatchToProps = dispatch => {
    return {
        resetTransaction: ()=> dispatch(resetTransaction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionModal);