import { Alert, Col, Row } from "react-bootstrap";

export default function ConnectHelp(props) {
    return (
        <div>
            {props.connected === false && <div onClick={()=> props.onConnect()} style={{width: "250px", margin: "auto"}} className="text-center cursor-pointer bg-light py-2 border rounded-3 border-secondary mt-5">
                <img  alt="metamask" src="https://images.ctfassets.net/9sy2a0egs6zh/4zJfzJbG3kTDSk5Wo4RJI1/1b363263141cf629b28155e2625b56c9/mm-logo.svg"/>
                <div className="text-light-2 small mt-1">Connect your wallet</div>
            </div>}
            <Row className="mt-5">
                <Col md={{span: 6, offset: 3}} className=" rounded-3 p-4">
                    <div className="h5 fw-thin mb-4 text-center">
                        Add Mumbai-Testnet Polygon Network
                    </div>
                    <Alert variant="primary">
                        <div className="small fw-bold"><i className="fa fa-info-circle me-2"></i>Note</div>
                        <div className="small">Please make sure you have already installed <a href="https://metamask.io/" target="_blank" rel="noreferrer">Metamask</a>!</div>
                    </Alert>
                    <ul>
                        <li className="mb-4">
                            Navigate to <a href="https://mumbai.polygonscan.com" target="_blank" rel="noreferrer">mumbai.polygonscan.com</a>
                        </li>
                        <li className="mb-4">
                            Scroll down to the bottom of the page and click on the button Add Mumbai Network. <br/>
                            <img className="mt-3" width="100%" alt="testnet" src="https://docs.polygon.technology/img/metamask/testnet-button.png" />
                        </li>
                        <li>
                            Once you click the button you will see a Metamask Notification, now click on Approve. You will be directly switched to Polygon’s Testnet now in the network dropdown list. You can now close the dialog.<br/>
                            <img className="mt-3" width="30%" alt="testnet" src="https://docs.polygon.technology/img/metamask/testnet-addnetwork.png" />

                        </li>
                    </ul>
                    <Alert variant="primary mt-4 mb-4">
                        If you are facing any issue, <a rel="noreferrer" href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/#add-the-polygon-network-manually" target="_blank">
                            Add the Network Manually<i className="ms-2 fa fa-up-right-from-square"/>
                        </a>
                    </Alert>
                </Col>
            </Row>
        </div>
    )
}