import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

import LendingAssets  from './components/LendingAssets';
import BorrowingAssets  from './components/BorrowingAssets';
import Balance from '../dashboard/components/Balance';
import WalletBalances from '../WalletBalances';

function Portfolio (props) {

    return <Container>
        <WalletBalances />
        <Balance />
        <Row>
            <Col md={6}>
                <LendingAssets />
            </Col>
            <Col md={6}>
                <BorrowingAssets />
            </Col>
        </Row>
        </Container>
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot")
    }
}

const mapDispatchtoProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(Portfolio)