import {useState} from 'react';
import {connect} from 'react-redux';

import {Card, Row, Col, Button, Spinner} from 'react-bootstrap';
import {decimal2Fixed, fixed2Decimals, getTokenIcon, mul, toPrecisin} from '../../../utils';
import LendModal from './LendModal';

function LendAssets(props) {
    // const [activeLendToken, setActiveLendToken] = useState(props.assets.get(0));
    const [activeLendToken, setActiveLendToken] = useState(null);

    const getAPY = address => {
        let apy = props.accountSnapshot.getIn([address, "lendAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getPoolSize = (address, decimals) => {
        if(props.accountSnapshot.hasIn([address, "totalSupply"])) {
            let supply = mul(props.accountSnapshot.getIn([address, "totalSupply"]), fixed2Decimals(props.accountSnapshot.getIn([address, "exchangeRateStored"]), 18) );
            return numFormatter(fixed2Decimals(supply, decimals));
        } else {
            return <Spinner size="sm" animation="grow" variant="secondary" />
        }  
    }

    const getUtilization = (address) => {
        const cash  = props.accountSnapshot.getIn([address, "totalCash"]);
        const borrow =  props.accountSnapshot.getIn([address, "totalBorrowsCurrent"]);
        if (cash === undefined || borrow === undefined) {
            return <Spinner size="sm" animation="grow" variant="secondary"/>
        } else if (cash === 0) {
            return "---";
        } else {
            // console.log("Borrow", borrow, "Cash", cash);
            return `${(((borrow)/cash)*100).toFixed(2)}%`
        }
    }

    const numFormatter = num => {
        if(num ==0) {
            return 0;
        } else if(num > 999 && num < 1000000){
            return (num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            return (num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 900){
            return num; // if value < 1000, nothing to do
        }
    }
    // console.log(decimal2Fixed(119.636, 18))

    return (
        <Card body className="mt-5">
            <Row>
                <Col className="g-0">
                    <div className="fs-6">Supply Tokens</div>
                    <div className="small text-muted">Tokens you can lend to earn interest</div>
                </Col>
            </Row>
           
            <div className="token-list">
                {props.assets.map((token, j)=> <Row key={j} className="token-list-item align-items-center">
                    <Col>
                        <div className="title">Asset</div>
                        <div className="value"><img src={getTokenIcon(token.get("symbol"))} alt="token"/>{token.get("symbol")}</div>
                    </Col>
                    <Col>
                        <div className="title">APY</div>
                        <div className="value">{getAPY(token.get("address"))}</div>
                    </Col>
                    <Col>
                        <div className="title">Pool Size</div>
                        <div className="value">{getPoolSize(token.get("address"), token.get("decimals"))}</div>
                    </Col>
                    <Col>
                        <div className="title">Utilization</div>
                        <div className="value">{getUtilization(token.get("address"))}</div>
                    </Col>
                    <Col className='d-grid justify-content-end'>
                        <Button variant="primary" className="action" onClick={()=> setActiveLendToken(token)}>Supply</Button>
                    </Col>
                </Row>)}
            </div>
            {activeLendToken !==null && <LendModal
                show={activeLendToken !== null}
                token={activeLendToken}
                lendAPY={getAPY(activeLendToken.get("address"))}
                onHide={()=> setActiveLendToken(null)}
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets").filter((item)=> item.get("lendEnabled")),
        accountSnapshot: state.get("accountSnapshot")
    }
}

export default connect(mapStateToProps, {})(LendAssets);