import {useState} from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTokenIcon } from '../../../utils';
import Approve from './modal/Approve';
import Borrow from './modal/Borrow';
import Repay from './modal/Repay';

function BorrowModal(props) {
    const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : "Borrow & Buy");
    
    const tabs = ["Borrow & Buy", "Repay"];
    const borrowToken = props.tokens.get("borrowToken");
    const targetToken = props.tokens.get("targetToken");

    const isApproved = props.uTokenMeta.hasIn([borrowToken.get("uAddress"), "allowance"]) && Number(props.uTokenMeta.getIn([borrowToken.get("uAddress"), "allowance"])) > 0;

    const getTabContent = () => {
        if (!isApproved) {
            return (
                <Approve
                    token={borrowToken}
                    onHide={()=> props.onHide()}
                />
            )
        } else {
            if(activeTab === "Borrow & Buy") {
                return <Borrow
                    targetToken={targetToken}
                    borrowToken={borrowToken}
                    borrowAPY ={props.getAPY(borrowToken.get("address"))}
                    onHide={()=> props.onHide()}
                />
            } else {
                return <Repay 
                    token={borrowToken}
                    onHide={()=> props.onHide()}
                />
            }
        }
      
    }
    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modal-header-token'>
                        <div className="icon">
                            <img src={getTokenIcon(targetToken.get("symbol"))} alt={targetToken.get("symbol")}/>
                        </div>
                        <div className='text-light-2 mt-2 fs-5'>{targetToken.get("symbol")}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='text-light-1 mt-3 text-center'><small>
                    Buy {targetToken.get("symbol")} by borrowing {borrowToken.get("symbol")}
                </small></div>
                <Row className='modal-tab mt-4 g-0'>
                    {tabs.map((tab, i)=> {
                        return (
                            <Col
                                onClick={()=> setActiveTab(tab)}
                                className={`modal-tab-item ${tab === activeTab ? "active" : ""}`}
                                key={i}
                            >
                                {tab} 
                            </Col>
                        ) 
                    })}
                </Row>
                <div className='p-4'>
                        {getTabContent()}
                    </div>
            </Modal.Body>
        </Modal>
    ) 
}

const mapStateToProps = state => {
    return {
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        themeMode: state.get("themeMode")
    }
 }   
 
 export default connect(mapStateToProps, {})(BorrowModal);